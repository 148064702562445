.tc-header  {
  justify-content: left;
}
.tc-title  {
  font-size: 28px;
}
.popup-footer {
  height: 500px;
  width: 100%;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}

.float_center {
  float: right;

  position: relative;
  left: -43%; 
  text-align: left;

}