
.vp-lang-dropdown{
  margin-left: auto !important;
  margin-top: -6px !important;
  padding-right: 13px !important;
    .vdl-dropdown-button__icon {
        font-size: 12px;
        top: -1px;
    }
    .vdl-popup {
        left: -20px;
      } 
}
.vp-cancel-icon{
  margin-left: auto !important;
  padding-right: 10px !important;
}
.vp-required-icon{
  color: red !important;
  font-size: 20px !important;
  margin-left: 4px !important;
}
.vp-oneoption-div{
  margin-bottom:3px !important;
}
.vp-oneoption-label{
  color: #6a6a6a;
  font-size: 17px !important;
}
.vp-oneoption-req-icon{
  color: red !important;
  font-size: 20px !important;
}
.vp-handicon-span{
  color: #086274; 
  font-size: 13px
}
.vp-ssn-info-tooltip{
  margin-left: 1% !important;
}
div.text-size input {
  font-size: 14px !important;
  color: #6a6a6a;
  font-weight: 400;
  width: 100%;
}

.sivoptions-align {
  margin-left : 24px;
  width : 50% 
}

.monthdropdown-align{
  margin-left: 24px;
}

.daydropdown-align{
  margin-left: 10px;
  width: 50%;
}

.daydropdown-cpc-align { 
    margin-left: 10px;
    width: 25%;
  }
 
.verifyuser-pageinfo {
    font-weight: 300 !important;
    font-style: italic;
    color: #000 !important;
}

.page-title {
  min-height: 38px;	
  color: #000000;	
  font-size: 24px;	
  font-weight: 600;	
  line-height: 38px;	
  text-align: center;
}

.registration-code {
  color: #000000;
  line-height: 19px;
  text-align: center;
  float:  left !important;
}

.findme-registration-code {
  height: 17px;
  width: 273px;
  color: #000000;
  font-size: 14px;
  line-height: 12px;
}

.vdl-password-strength__rule-icon {
    margin-right: 0px !important;
}
// web 
 @media (min-width: 876px) {
  .siv-options-height{
    min-height: 61px !important;
  }
  .regcode-textbox {	
      box-sizing: border-box !important;	
      height: 37px !important;	
      width: 57% !important;	
      border: 1px solid #66AFE9 !important;	
      background-color: #FFFFFF !important;	
      box-shadow: 0 0 4px 0 #79B9EE !important;
      margin: 0 auto;
  }
  .useridpsw-padding {
      padding-left: 15%;
      padding-right: 10%;
  }
  .identity-page-padding {
      padding-left: 21%;
      padding-right: 19%;
  }
  .identity-page-lock-padding {
    padding-right: 14%;
  }
  .dob-padding-align {
      padding-left: 21%;
      padding-right: 19%;
  }
  .monthdropdown-cpc-align{
    width: 40%;
  }
  .yeardropdown-cpc-align {
    margin-left: 10px ;
    width: 31%;
    }
  .verify-user-reg-code-text{
    width: 70% !important;
    margin: 0 auto !important;
    }  
 }   
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {
  .siv-options-height{
    min-height: 61px !important;
  }
  .regcode-textbox {	
      box-sizing: border-box !important;	
      height: 37px !important;	
      width: 57% !important;	
      border: 1px solid #66AFE9 !important;	
      background-color: #FFFFFF !important;	
      box-shadow: 0 0 4px 0 #79B9EE !important;
      margin: 0 auto;
  }
  .useridpsw-padding {
     padding-left: 15%;
     padding-right: 10%;
  }
  .identity-page-padding {
      padding-left: 21%;
      padding-right: 19%;
  }
  .identity-page-lock-padding {
    padding-right: 14%;
  }
  .dob-padding-align {
      padding-left: 21%;
      padding-right: 19%;
  }
  .monthdropdown-cpc-align{
    width: 40%;
  }
  .yeardropdown-cpc-align {
    margin-left: 10px ;
    width: 31%;
  }
  .verify-user-reg-code-text{
    width: 70% !important;
    margin: 0 auto !important;
    } 
 }

  //mobile 
 @media (max-width: 479px) {
   .siv-options-height{
    min-height: 61px !important;
   }
 .regcode-textbox {	
    box-sizing: border-box !important;	
    height: 37px !important;	
    width: 84% !important;	
    border: 1px solid #66AFE9 !important;	
    background-color: #FFFFFF !important;	
    box-shadow: 0 0 4px 0 #79B9EE !important;
    margin: 0 auto;
 }

 .findme-registration-code {
   width: 140px;
 } 
 .useridpsw-padding {
    padding-left: 15%;
    padding-right: 10%;
  }
  .identity-page-padding {
    padding-left: 14%;
    padding-right: 15%;
  }
  .identity-page-lock-padding {
    padding-right: 10%;
  }
  .dob-padding-align {
    padding-left: 14%;
    padding-right: 10%;
  }
  
  .monthdropdown-cpc-align {
    width: 44%;
  }

  .yeardropdown-cpc-align {
    margin-left: 10px ;
    width:27%;
  }
  .verify-user-reg-code-text {
    width: 100% !important;
    margin: 0 auto !important;
  }
 .align-mobile-bev-modal {
   margin-top: 2%;
 }    
}  

