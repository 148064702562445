.iy-form-sp-div{
    padding-left: 14px !important ;
    height: 1.5em !important;
}
.iy-form-cancel-div{
    margin-left: auto !important;
    padding-right: 10px !important; 
}
.iy-form-hr{
    border-top: 1px solid #BEBEBE !important; 
}
.iy-form-title-text{
    text-align: center !important;
    font-weight: normal !important;
}
.iy-form-required-icon{
    color: #940a0a !important;
    font-size: 20px !important;
    margin-left: 4px !important;
}
.iy-form-oneoption-div{
    padding-bottom:3px !important;
}
.iy-form-oneoption-label{
    color: #6a6a6a ;
    font-size: 17px !important;
}
.iy-form-one-option-required-icon{
    color: #940a0a !important;
    font-size: 20px !important;
}
.iy-form-handicon-span{
    color: #086274 !important;
    font-size: 13px !important;
}
.iy-form-handicon-label{
    margin-left: 1% !important;
}
.iy-form-dropdown-div{
    display: inline-flex !important;
    width:100% !important;
}
.iy-form-month-dropdown-div{
    display: inline-flex !important;
    width:101% !important;
}