.v401-multiorg-info-label{
    font-weight: 400 !important;
    color: #000 ;
    padding-left: 7% !important;
    padding-right: 7% !important;
    text-align: center !important;
}
.v401-form-req-icon{
    color: red !important;
    font-size: 20px !important;
    margin-left: 4px !important;
}
.v401-dropdown-div{
    display: inline-flex !important;
    width: 100% !important;
}
.v401-multiorg-div{
    padding-bottom: 40px !important;
}
.v401-multiorg-val{
    color: #086274 !important;
    font-weight: normal !important;
}

@media (min-width: 876px) {
    .v401mo-form-padding{
     padding-left: 21%;
     padding-right: 19%;
 }

 .v401-form-padding{
    padding-left: 21%;
    padding-right: 19%;
}

.v401dob-form-padding{
    padding-left: 21%;
    padding-right: 19%;
}

.v401dob-column-width{
    width:27%;
}

}

@media screen and (max-width: 875px) and (min-width: 480px){
 .v401mo-form-padding{
     padding-left: 21%;
     padding-right: 19%;
 }

 .v401-form-padding{
    padding-left: 21%;
    padding-right: 19%;
}

.v401dob-form-padding{
    padding-left: 21%;
    padding-right: 19%;
}

.v401dob-column-width{
    width:27%;
}

}

@media (max-width: 479px) {
 .v401mo-form-padding{
    padding-left: 14%;
    padding-right: 15%;
 }

 .v401-form-padding{
    padding-left: 30px;
    padding-right: 30px;
}

.v401dob-form-padding{
    padding-left: 30px;
    padding-right: 30px;
}

.v401dob-column-width{
    width:26%;
}

}