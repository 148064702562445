.cs-chevron-icon {
    font-size: 24px !important;
    margin-top: auto;
    margin-bottom: auto;
    color: #086274;
}

.cs-multi-prod-layout {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
    :hover{
        cursor: pointer;
   }
}

.cs-multi-prod-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.cs-multi-prod-box {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
    min-height: 60px;
    margin-top: 2px;
}

.cs-signin-btn {
    min-height: 35px;
    min-width: 184px;
}

.cs-signin-layout {
    width: 100%;
    text-align: center;
    margin-top: 8px;
}

.cs-text-label {
    color: #086274;
    font-weight: 500;
    font-size: 14px;
}

.cs-secure-page-div{
    padding-left: 14px !important;
    height: 1.5em !important;
}
.cs-secure-page-hr{
    border-top: 1px solid #BEBEBE !important;
}
.cs-footer-links-div{
    text-align: center;
    padding-bottom: 10px;
    margin-top: -5px;
}
.cs-confirmation-label-div{
    font-size: 12px;
}
.cs-footer-color {
    background: #ffffff !important;
}
.cs-text-center {
    text-align: center;
}
.cs-copy {
    color: #086274;
    margin-left: 1%;
    cursor: pointer;
}
.cs-background-white {
    background: white !important;
}

.cs-pop-over {
    text-align: center;
    
}
.cs-btn-border {
    border: 1px solid lightgrey;
}
.cs-label {
     text-align: center;
     font-weight: 500;
     font-size: 16px;
}
 .cs-envelope-margin {
     margin-top:auto !important;
     margin-bottom: auto !important;
}
 .cs-icon-margin {
     margin-top: 2px;
     margin-bottom: auto !important;
}
 .cs-text-lbl {
     font-weight: 500;
     font-size: 14px !important;
}
 .cs-activate-lbl {
     font-weight: 500;
     font-size: 14px;
}
 .cs-title {
     text-align: center !important;
     font-size: 24px !important;
     font-weight: 500 !important;
}
 .cs-border-white {
     display: block;
     height: 1px;
     border: 0;
     border-top: 1px solid #ccc;
     margin: 1em 0;
     padding: 0;
}
 .cs-user-id {
     color: #086274;
     font-weight: 500;
     font-size: 14px;
     cursor: pointer;
}
 .cs-check-circle {
     font-size: 82px !important;
     color: limegreen;
     width: 100%;
     margin: auto;
}
.cs-mobile-icon {
    font-size: 600%;
    color: #086274;
    width: 100%;
    margin: auto;
}
 .cs-phone-icon {
     font-size: 25px !important;
     margin-left: 2px !important;
}
 .cs-envelope {
     font-size: 14px;
}
 // web 
 @media (min-width: 876px) {
     .cs-margin-top {
         margin-top : -1% !important;
    }
     .cs-email {
         margin-left: -3% !important;
    }
     .cs-phone {
         margin-left: -3% !important;
         margin-top: auto;
         margin-bottom: auto;
    }
     .cs-services {
         margin-top: 1% !important;
         margin-left: 2% !important;
    }
     .cs-margin-bottom {
         margin-bottom: 5px !important;
    }
     .cs-phone-activation {
         margin-left:-11%;
         color: #086274;
         text-decoration: none !important;
         margin-top: auto;
         margin-bottom: auto;
    }
  /*   .registration-page-container .registration-content {
         padding: 35px !important;
         padding-bottom: 10px !important;
    }*/
     .cs-margin-footer-top {
        margin-top: -4%;
    }
     .cs-andriod-badget {
height: 60px;
    width: 26% !important;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    position: relative;
         
    }
     .cs-apple-badget {
        height: 60px;
        padding: 10px;
        width: 26% !important;
        cursor: pointer;
        display: inline-block;
        top: 10px;
        position: relative;
        
    }
}
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {
     .cs-margin-top {
         margin-top : -1% !important;
    }
     .cs-email {
         margin-left: -3% !important;
    }
     .cs-phone {
         margin-left: -3% !important;
         margin-top: auto;
         margin-bottom: auto;
    }
     .cs-services {
         margin-top: 1% !important;
         margin-left: 2% !important;
    }
     .cs-phone-activation {
         margin-left:-11%;
         color: #086274;
         text-decoration: none !important;
         margin-top: auto;
         margin-bottom: auto;
    }
     .cs-andriod-badget {
         height: 60px;
    width: 26% !important;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    position: relative;
    }
     .cs-apple-badget {
        height: 60px;
        padding: 10px;
        width: 26% !important;
        cursor: pointer;
        display: inline-block;
        top: 10px;
        position: relative;
        
    }
}
 //mobile 
 @media (max-width: 479px) {
     .cs-margin-top {
         margin-top : 0% !important;
    }
     .cs-email {
         margin-left: -7% !important;
    }
     .cs-phone {
         margin-left: -7% !important;
         margin-top: auto;
         margin-bottom: auto;
    }
     .cs-services {
         margin-top: 1% !important;
         margin-left: 2% !important;
    }
     .cs-phone-activation {
         margin-left:-6%;
         color: #086274;
         text-decoration: none !important;
         margin-top: auto;
         margin-bottom: auto;
    }
    .cs-multi-prod-box {
        background-color: white !important;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 22px;
        padding-bottom: 22px;
        min-height: 60px;
        margin-top: 2px;
        margin-left: -40px !important;
        margin-right: -40px !important;
    }
     .cs-andriod-badget {
          height: 40px;
    width: 28% !important;
    cursor: pointer;
    display: inline-block;
    top: 6px;
    position: relative;
    }
     .cs-apple-badget {
      cursor: pointer;
    width: 26% !important;
    height: 27px;
    }
}
 