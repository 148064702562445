.vk-cancel-icon{
    margin-left: auto !important;
    padding-right: 10px !important;
}
.vk-page-form-div{
    padding-bottom:40px !important
}
.vk-timer-div{
    float: right !important;
}
.vk-answersobj-div{
    padding-left: 6px !important;
}
.vk-answerkba-title-div{
    margin-top: 11px !important;
    margin-bottom: 5px !important;
}
.vk-challenge-verf-div{
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-size: 14px !important;
}
.vk-sbt-btn-div{
    width: 100% !important;
    text-align: center !important;
    margin-top: 25px !important;
}
.vk-back-btn-div{
    width: 100% !important;
    text-align: center !important;
    margin-top: 5px !important;
    padding-bottom: 15px !important;
}
.vk-back-btn-label{
    margin-left: 5px !important;
}
.vk-form-padding {
        // padding:30px !important;
         padding-top: 0px !important;
         width: 100%;
    }

.vk-margin-h {
    margin-left: auto;
    margin-right: auto;
}


.vk-heading {
         text-align: center;
         font-weight: 500;
         margin-bottom: 10px;
         font-size: 14px;
        
    }

.vk-row-size {
         margin-top: 3px;
         min-height: 50px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }

 .vk-col-11 {
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
}

.vk-text-label {
         color: #086274;
         font-weight: 500;
         margin-left: 5%;
    }
.vk-font-size {
     font-size: 14px;
}

.vk-col-1 {
    width: 5%;
} 

.vk-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    } 


    .vk-cancel-btn {
         text-decoration: none !important;
         margin: 0 auto;
         display: block !important;
    }     

.kba-info-text-align {
    margin-left: 35px !important;
    margin-right: 35px !important;    
}

.kba-text-spacing {
    margin-bottom: 10px !important;
}
