

.contact-info-secure-page-div{
    padding-left: 14px !important;
    height: 1.5em !important;
}
.adp-icon-padding{
    color: #940a0a!important;
    height: 1.5em !important;
    padding-left: 10px !important;
    margin-bottom: 10px !important;
}
.contact-info-hr{
    border-top: 1px solid #BEBEBE !important;
}
.cf-info-text-1{
    font-weight: bold !important;
}
.cf-info-text-2{
    color: #4a4a4a ;
}
.cf-margin-top-0{
    margin-top: 0 !important;
}
.cf-personal-email-radio-div{
    margin-top: 5px !important;
}
.cf-required-icon{
    color: #940a0a !important;
}
.cf-tc-div{
    margin-top: 1% !important;
}
.cf-add-phone-div{
    margin: 0 auto !important;
    text-align: center !important; 
}
.cf-add-secondary-contact-div{
    margin: 0 auto !important;
}
.cf-submit-btn-div{
    padding-top: 20px;
    padding-bottom: 30px;
    background: #fff;
}


input[type="text"]:disabled {
    opacity: 1;
}
.cf-cancel-align {
    margin-left: auto !important;
} 
.cf-cancel-btn {
         text-decoration: none !important;
         margin: 0 auto !important;
         display: block !important;
    }
     
.cf-iframe-touch {
    -webkit-overflow-scrolling: touch !important;
    overflow-y: scroll;
}  
/*label {
     margin-bottom: 9px !important;
}*/
 .cf-font-size-txt {
     font-size: 16px !important;
     color: #4a4a4a ;
}
 .cf-font-size-btn {
     font-size: 12px !important;
}
 .cf-registration-form .adp-form {
     margin-top: 10px;
}
 .cf-radio-toggle {
     height: 16px !important;
     color: #086274 !important;
}
 .cf-background-white {
     background-color: #fff;
     padding: 14px;
     width: 100% !important;
}
 .cf-border-white {
     display: block;
     height: 1px;
     border: 0;
     border-top: 1px solid #ccc;
     margin: 1em 0;
     padding: 0;
}
 .cf-title {
     font-size: 18px;
     font-weight: 600;
     text-align: center;
}
 // web 
 @media (min-width: 876px) {
    .contact-info-form{
        margin-top: -5% !important;
    }
     .cs-padding-top {
         padding-top: 15px;
    }
    .cs-footer-padding-top {
        padding-top: 30px;
   }
     .cf-registration-form {
         padding: 30px !important;
         padding-top: 0px !important;
         padding-bottom: 0px !important;
    }
    
     .cf-layout-width {
         max-width: 600px !important;
    }
     .cf-lbl-align {
         margin-left: -9%;
         width: 109% !important; 
    }
     .cf-vdl-registration {
         height: 100%;
         background:white !important;
         overflow: auto;
    }
     .cf-vdl-container {
         max-width: 600px;
    }
     .cf-vdl-countrycode {
         margin-left: -34%;
    }
     .cf-vdl-extn {
         margin-left: -8%;
         width: 110% !important;
    }
     .cf-lbl-mobile {
         width: 135%;
         margin-left: -15%;
    }
     .cf-plus-circle {
         margin-top: 2%;
         color: #086274;
    }
     .cf-vdl-width {
         width: 46% 
    }
     .cf-vdl-phone-margin {
         margin-left: -7% 
    }
     .cf-btn-cnt{
         margin: 0 auto;
         display: block !important;
         font-size: 12px !important;
    }
 /*    .registration-page-container .registration-content{
         padding: 10px !important;
    }*/
     .cf-add-phn-btn{
         margin-left: -2% !important;
    }
}
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {
     .cs-padding-top {
         padding-top: 15px;
    }
    .contact-info-form{
        background: #F8F8FF !important;
    }
    .cs-footer-padding-top {
        padding-top: 30px;
   }
     .cf-registration-form {
         padding: 30px !important;
         padding-top: 0px !important;
         padding-bottom: 0px !important;
    }
     .cf-layout-width {
         max-width: 720px !important;
    }
   
     .vdl-col-md-6 {
         flex: 0 0 100%;
         max-width: 33.34%;
    }
   /*  .registration-page-container .registration-content{
         padding: 10px !important;
    }
     .registration-content .registration-form {
         padding: 20px !important;
    }*/
     .cf-plus-circle {
         margin-top: 2%;
         color: #086274;
    }
     .cf-btn-cnt{
         margin-top: 3% !important;
         margin: 0 auto;
         margin-bottom: 10px;
         display: block !important;
         font-size: 12px !important;
    }
     .cf-vdl-landline {
         margin-left: -28%;
         width: 161%;
    }
     .cf-lbl-mobile {
         margin-left: -15%;
         width: 115%;
    }
     .cf-add-phn-btn {
         margin-left: -2% !important;
    }
}
 .cf-required {
     :after {
         content: '*';
         color: #940a0a;
    }
}
 // Modal CSS 
/* .vdl-alert--default-style.vdl-alert--warning .vdl-alert__icon > svg {
     color: #940a0a;
     fill: #940a0a;
}
 .vdl-alert--default-style.vdl-alert--warning {
     color: #940a0a;
     background-color: #fff;
     border: 2px solid #940a0a;
}
 .vdl-alert--default-style.vdl-alert--warning .vdl-alert__bar {
     background-color: #fff;
}
*/
 .vdl-modal-dialog.md .vdl-modal-content {
     width: 568px;
     min-height: 190px;
}
 @media screen and (max-width: 480px) {
     .vdl-modal-dialog.sm .vdl-modal-content, .vdl-modal-dialog.md .vdl-modal-content, .vdl-modal-dialog.lg .vdl-modal-content, .vdl-modal-dialog.xl .vdl-modal-content {
         width: 88% ;
    }
}
 //mobile 
 @media (max-width: 479px) {
    .contact-info-form{
        background: #F8F8FF !important;
    }
      #downIndicator {
        display: none;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 99;
        font-size: 10px;
        border: none;
        padding:15px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        background-color:#000000;
        opacity: 0.5;
        color: white;
        border-radius: 50%;
        display:block;
      }

      .downIndicatorMobileWeb {
        bottom: 30px !important;
      }

      .down-arrow{
          margin: -8px 0px 5px 0px !important;
          font-size: 18px;
          color: #ffffff;
      }

    
    .cf-registration-form {
        padding: 25px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
   }

     .cf-plus-circle {
         margin-top: 3%;
         color: #086274;
    }
     .cf-lbl-mobile {
         margin-left: -12%;
         width: 112%;
    }
     .cf-vdl-landline {
         margin-left: -26%;
         width: 200%;
    }
     .cf-vdl-extn {
         width: 63% !important;
         margin-left: 36%;
    }
     .cf-btn-cnt{
         margin: 0 auto;
         margin-bottom: 10px;
         display: block !important;
         margin-top: 3% !important;
         font-size: 12px !important;
    }
     .cf-vdl-font-size {
         margin-bottom: 3% !important;
    }
    .cf-add-phn-btn {
         margin-left: -4% !important;
    }
}
 .cf-vdl-font-size {
     font-size: 14px;
}
 .cf-vdl-font-size-lbl {
     font-size: 14px;
}
/* .vdl-alert {
     font-size: 14px;
}
.vdl-alert--default-style.vdl-alert--error{
     width: 100% !important;
}
 .vdl-alert--default-style.vdl-alert--warning .vdl-alert__icon > svg {
     color: #940a0a;
     fill: #940a0a;
}
 .vdl-alert--default-style.vdl-alert--warning {
     color: #940a0a;
     background-color: #fff;
     border: 2px solid #940a0a;
}
 .vdl-alert--default-style.vdl-alert--warning .vdl-alert__bar {
     background-color: #fff;
}
 */
 .vdl-footer {
     z-index: 20;
}
 