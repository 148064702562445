// Universal items
/*.vdl-alert--inline-style.vdl-alert--error {
    color: #940a0a;
    padding: 10px !important; 
    margin-bottom: 10px;
} 
label {
  display: inline-block;
  font-size: 16px !important;
} */

/*.vdl-alert--default-style.vdl-alert--warning .vdl-alert__icon > svg {
    color: #940a0a;
    fill: #940a0a;
}

.vdl-alert--default-style.vdl-alert--warning {
    color: #940a0a;
    background-color: #fff;
    border: 2px solid #940a0a;
}

.vdl-alert--default-style.vdl-alert--warning .vdl-alert__bar {
    background-color: #fff;
}*/

.cancel-button-align:hover {
    background-color: transparent !important;
}

.form-group {
  margin-bottom: 12px;
}

// Registration Error
.registration-error {
  .adp-inline-message {
    margin: 0;
  }
}

.btn-align-right {
  float: right;
}

/*.popover-align {
  color: #000;
  font-weight: normal;
  font-size: 13px; 
}*/

/*.vdl-popover.right > .arrow:after {
    border-right-color: #fff !important;
}*/


.vdl-modal--alert .vdl-modal-content {
    background-color: #DCDCDC !important;
}

// The form
.registration-form {
  .adp-form {
   // margin-top: 10px;

   /* .form-group {
      label {
        color: $neutral-dark;
      }
    }*/

    .input-group-full {
      position: relative;

      svg {
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 5;
        font-size: 24px;
        opacity: 0.7;
        margin-top: 6px;
        color: $neutral-dark;
      }
    }

    // User ID
    .form-align {
      margin-bottom: 10px;
    }

    // Submit Btn
    .submit {
      .btn {
        width: 100%;
      }
    }

    .forgot-password {
      font-weight: $font-weight-base;
    }
  }
}

/*.language-popover {
  .vdl-dropdown-button {
    .vdl-button {
      &.link {
        color: $neutral-dark;
        padding-right: 0;
      }
    }
  }
}

.popover-content {
  color: #fff;
}

$border_right_color_1: #940a0a;

.vdl-popover {
  &.right {
	>.arrow {
		&:after {
			border-right-color: $border_right_color_1;
		}
	}
}
}*/

.vdl-modal-dialog.md .vdl-modal-content {
    width: 568px;
    min-height: 190px;
}

// Small Screens
@media (max-width: 768px) {
  .registration-content {
    .adp-form {
      .submit {
        button {
          width: 100%;
        }
      }
    }
  }
  .dob-column-width {
    width: 134px;
  }
  .cancel-button-align {
    color: #086274;
    background-color: transparent;
    margin-top: 0px;
  }
}

// Greater than mobile
@media (min-width: 768px) {
  // Registration Layout
  .registration-content {
   // margin: 0 auto 2px;

    .registration-form {
     // padding: 0 $content-inner-padding;
    }

    // Submit Button
    .adp-form {
      .submit {
        button {
          width: auto;
        }
      }
    }
  }
  .dob-column-width {
	width: 134px;
  }
  .cancel-button-align {
    color: #086274;
    background-color: transparent;
    margin-top: 0px;
  }
}

@media screen and (max-width: 480px) {
.vdl-modal-dialog.sm 
.vdl-modal-content, 
.vdl-modal-dialog.md 
.vdl-modal-content, 
.vdl-modal-dialog.lg 
.vdl-modal-content, 
.vdl-modal-dialog.xl 
.vdl-modal-content {
    width: 88% !important;
  }
 
  .cancel-button-align {
    color: #086274;
    background-color: transparent;
    margin-top: 0px;
  }
}