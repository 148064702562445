/*.vdl-textbox > input {
  height : 40px !important;
  font-size: 16px !important;
  font-weight: 500;
}

.vdl-modal-close > svg {
    height: 100%;
    width: 100%;
    margin-right: -73px;
}*/
/*.vdl-dropdown-list__input-container {  
  height : 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  font-weight: 500;
  -webkit-overflow-scrolling: touch !important;
   
}

.vdl-popover {
    position: absolute;
    z-index: 1060;
    max-width: 276px;
    border: 1px solid #bbb;
    background-color: #fff;
    font-family: Circular,sans-serif;
    width: auto;
    min-width: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

.vdl-popover.bottom .arrow {
    top: -9px !important;
}*/

.vdl-popover {
  max-width: 276px;
} 
.div-h5 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.focus-selector:focus {
  border: 2px solid #324fa5;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(50 79 165 / 60%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.highlight-options {
  border: 1px solid rgb(190, 190, 190);
}

.back-icon-align{
  margin-bottom: 1px !important;
  font-size: 13px !important;
  
}
.button-padding {
  margin-top: 35px !important;
}

.br-padding {
  margin-top: 20px !important;
}
.vdl-dropdown-list__picker{
  height: 100% !important;
  vertical-align: middle !important;
  outline: none !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  padding: 0 10px !important;
  color: #086274 !important;
  margin-top: -2px !important;
  font-size: 20px !important;
}

.vdl-alert--animate {
  // sass-lint:disable no-important 
    -webkit-animation-name: none !important;
    animation-name: none !important;
  }
  
  .label-fields {
    display: block;
    color: #000;
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 5px;
  }
  
  .vdl-modal-close {
      height: 29px !important;
  }
  
  .ie-margin-auto-fix {
      margin-top: 12px !important;
      margin-bottom: auto;
  }
  
  .ie-margin-auto-icon {
    font-size: 23px !important;
      margin-top: 12px !important;
      margin-bottom: auto !important;
      margin-left: -1px !important;
      color: #086274;
  }
  
  .ie-chevron-icon {
      font-size: 20px !important;
      margin-top: 12px !important;
      margin-bottom: auto;
      color: #086274;
  }
  
  input {
    line-height: 0% !important;
  }
  
  .safari-button-align {
    display: inline-block !important;
  }
  
  .lock-icon-align {
    margin-left: 2%;
    color: grey;
  }
  .tnc-modal-body-footercolor {
    background-color: #e4e4e4;
   -webkit-overflow-scrolling: touch;
  }
  
  .page-title {
      font-size: 24px !important;
  }    
  
  .page-title-for-lengthy-text {
       font-size: 21px !important;
  }     
  /*.vdl-dropdown-list__input__placeholder {
      color: #4a4a4a;
      font-style: italic;
      font-size: 14px;
  }
  
  .page-title {
      font-size: 30px !important;
      color: #000000;
      text-align: center;
      font-family: Circular !important;
      margin-top: 0px !important;
      font-weight: lighter;
  }
  
  .page-title-for-lengthy-text {
      font-size: 21px !important;
      color: #000000;
      text-align: center;
      font-family: Circular !important;
      margin-top: 0px !important;
      font-weight: normal;
      margin-left: 2px;
  }*/
  
  /*.vdl-button--primary, .vdl-button--secondary {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      text-shadow: none;
      text-transform: uppercase;
      background-image: none;
      border: 0;
      border-radius: 65px;
      box-shadow: none;
      padding: 10px 17px 9px;
  }*/
  
  .align-submit {
      margin-top: 38px;
      text-align: center;
  }
  .title-width {
      width: 100%
  }
  
  .vdl-container {
      margin-bottom: 40px;
      margin-top: 33px;
  }
  .modal-header {
    background-color: #ffffff !important;
    justify-content: left !important;
  }
  
  .modal-title-error {
    color: #940a0a;
    font-size: 17px;
    font-weight : bold;
  }
  
  .modal-title-info {
    color: #000;
    font-size: 17px;
    font-weight : bold;
  }
  
  .warn-icon-error {
    color: #940a0a !important;
  }
  
  .warn-icon-info {
    color: #ffb400 !important;
  }
  
  .warn-icon-info_pof {
    margin-top:2px;
    color: #ffb400 !important;
    font-size: 14px !important;
  }
  
  .modal-text {
    text-align: left !important;
    margin-top: 20px !important;
    color: #000000;
    font-weight: 400 !important;
  }
  
  .padding-align-allflows {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px;
  }
  
  .padding-align-inc-width {
      padding-left: 65px;
      padding-right: 65px;
      padding-bottom: 20px;
  }
  
  .modal-body-footercolor{
    background-color: #DCDCDC;
  }
  
  // Our Variables
  $content-inner-padding: 50px;
  
  // Button Override
  /*button {
    min-width: 130px;
  }*/
  
  // Logo
  .site-logo {
    h1 {
      margin: 0;
      padding: 30px;
      color: $white;
    }
  }
  
  // Opaque Login box
  .registration-page-container {
    margin: 0 auto;
    flex: 1;
  
    .registration-content {
      background: #fff !important;
    //  padding-left: 30px;
    //  padding-right: 30px;
    //  padding: 15px 15px 0;
      .language-popover {
        display: block;
      }
      .welcome-title {
        h1 {
          text-align: center;
        }
      }
      .floating-box {
        display: inline-block;
      }
    }
  
    .need-account {
      background: rgba($white, 0.9);
      button {
        margin-left: 10px;
      }
      h5 {
        font-weight: $font-weight-base;
      }
      .need-account-label {
        margin-right: 10px;
      }
      .need-account-button {
        text-decoration: none;
      }
    }
  }
  
  
   @media screen and (max-width: 875px) and (min-width: 480px) {
    .vdl-footer {
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      width: 100% !important;
    }
  
    .vdl-footer > ul{
      display: flex !important;
    }
  
  .registration-content {
      background:#fff !important;
  }
  
  .ssn-info-tooltip-align {
      display: inline;
      margin-left : 3%;
  }
   }
  
  
   @media (max-width: 479px) {
   .vdl-hidden-xs-ms {
   display: none !important; 
   background:white
  }
  body{
    height:100%;
    background: #fff !important;
    -webkit-overflow-scrolling: touch; 
  }
  
  .vdl-container{
    margin:0 !important;
    background: #fff !important;
    padding-left:0px !important;
    padding-right:0px !important;
   // background: #f8f8ff !important;
  }
  
  .registration-content {
      background:#fff !important;
      //padding-top: 10px;
    }
  
  .ssn-info-tooltip-align {
      margin-left : 3%;
  }
  .button-padding {
    margin-top: 50px !important;
  }
  
  }
  // Small Screens
  @media (max-width: 768px) {
   
    
    .need-account {
      h5 {
        margin-bottom: 15px;
      }
     /* button {
        &.secondary {
          display: block;
          width: 50%;
          line-height: 1em;
          margin-left: 0;
  
          &,
          &:active,
          &:focus,
          &:hover {
            background: transparent;
            border: 0;
            border-top: 1px solid $neutral-disabled;
            font-size: 18px;
            text-transform: none;
            color: $black;
            border-radius: 0;
            padding: 15px;
            text-align: left;
            height: auto;
          }
        }
      }*/
    }  
    .ssn-info-tooltip-align {
      margin-left : 3%;
    }
    .button-padding {
      margin-top: 50px !important;
    } 
  }
  
  // Greater than mobile
  @media (min-width: 768px) {
  .vdl-container {
    margin-bottom: 40px;

  }
    // Layout
    .registration-page-container {
      max-width: 540px;
  
      .need-account {
        padding: 30px;
      }
    }
  
    .vdl-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .ssn-info-tooltip-align {
      display: inline;
      margin-left : 3%;
    }
  }
  