
.pof-flag {
	margin-bottom: -1px !important;
	display: inline-block !important;
	width: 16px;
	height: 11px;
	background: url("../js/components/country-code/flags.png");
}	
.pof-us {
		background-position: -112px -143px;
}

.vf-cancel-icon{
    margin-left: auto !important;
    padding-right: 10px !important; 
}

.otp-footer-div{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.vf-title-label{
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}
.vf-marginbottom-0{
    margin-bottom: 0px !important;
}
.vf-marginbottom-10{
    margin-bottom: 10px !important;
}
.vf-otp-div{
    margin: 0 auto !important;
}
.vf-prc-info3-label{
    float: left !important; 
}
.vf-prc-otc-alert{
    text-align: center !important;
    color: red !important;
}
.vf-prc-otc-diffsubmit-div{
    width: 100% !important;
}
.vf-prc-otc-submit-div{
    text-align: center !important;
}
.vf-prc-nudge-skip-div{
    margin-top: 15px !important;
    margin-bottom: -15px !important;
}
.vf-prc-nudge-otp-skip-div{
    padding-bottom: 0px !important;
    padding-top: 15px !important;
}
.vf-prc-submit-div-pof{
    text-align: center !important;
    padding-top: 30px;
}
.vf-margin-auto{
margin-left: auto;
margin-right: auto;
}

.vf-prc-text {
    color: #4a4a4a;
}


.vf-font-size {
     font-size: 16px;
}

.vf-disabled{
      background-color: #bbb !important;
    cursor: not-allowed !important;
:hover{
             cursor: not-allowed !important;
        }
        }


 .vf-col-sm-2 {
      width : 20%;
        }

.vf-col-sm-9 {
      width : 75%;
        }
 .vf-col-sm-1 {
      width : 5%;
        }
        
.otp-textbox {
    box-sizing: border-box !important;
    height: 50px !important;
    width: 160px !important;
    border-radius: 6px !important;
    text-align: center;
    border: 1px solid #cfcfcf;
    background-color: #FFFFFF !important;
    font-size: 30px !important;
    letter-spacing: 3px !important;
    outline: none !important;
          }
 .otp-textbox:focus {
      border: 2px solid #324fa5;
      outline: 0;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(50 79 165 / 60%);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          }
.label-otp-align{
    text-align: center;
}        
 // web 
 @media (min-width: 876px) {
    .padding-align-inc-width-pof {
        padding-left: 65px;
        padding-right: 65px;
        padding-bottom: 20px;
      }

      .vf-emailphone-width-otp{
		width:55% !important;
    }
    
    .pof-text-align{
        font-size: 12px !important;
        margin-top: 5px !important;
        text-align: center !important;
    }
    .pof-countrycode-div{
        margin-left: 9%  !important;
        width:95%  !important;
    }
    .pof-padding{
        padding-left: 100px !important;
        padding-right: 120px !important;
    }
    .pof-error{
        text-align: center !important
   }
   .warn-icon-margin{
    margin-right:-10px;
}
     .vf-access-code{
         width: 100%;
         text-align: center;
    }
    .vf-row-padding{
        margin-left:40px !important;
        margin-right:40px !important;
    }
     .vf-row-size {
         margin-top: 3px;
         min-height: 60px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }
     .vf-phone-icon {
         font-size: 27px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
    .vf-kba-icon {
        font-size: 24px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
    }
    .vf-newPhone-icon {
        font-size: 27px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
   }
     .vf-email-icon {
         font-size: 22px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
     .vf-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
        }
     .vf-chevron-kba-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .vf-text-label {
         color: #086274;
         font-weight: 500;
    }
     .vf-text-label-kba {
         color: #086274;
         font-weight: 500;
    }
     .vf-text {
         color: #4a4a4a;
         margin-top: 3%;
    }
     .vf-text-margin-left {
         margin-top: auto !important;
         margin-bottom: auto !important;
    }
     .vf-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width:100%;
    }
     .vf-sub-form-padding {
         width: 100%;
      //   padding: 5%;
      //   padding-bottom: 0;
    }
     .vf-heading{
         text-align: center;
         font-weight: 500;
         font-size: 14px;
    }
    .vf-heading-2 {
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 6%;
         font-size: 14px;
    }
     .vf-sub-heading{
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 3%;
         margin-top: -3%;
         font-size: 14px;
    }
     .vf-otc-color {
         font-weight: 300 !important;
         color: #4a4a4a;
         font-size: 14px;
    }
     .vf-otc{
         font-weight: 300 !important;
         color: #4a4a4a;
         text-align: center;
         font-size: 16px;
    }
     .vf-otc-error {
         font-size: 16px;
         color: red;
         font-weight: 500 !important;
         text-align: center;
    }
     .vf-cancel-txt{
         font-size:14px;
         margin-left: 5% !important;
    }
     .vf-cancel-btn{
         text-decoration: none !important;
         margin-left: 30%;
         font-weight: 500;
    }
     .vf-otc-txt{
         margin: 0 auto;
         display: block;
         //width: 45%;
    }
     .vf-otc-resend{
         font-size: 14px;
         margin: 0 auto;
         display: block;
         text-decoration: none !important;
         font-weight: 500;
    }
     .vf-otc-submit {
         display: block;
         margin: 0 auto;
         margin-top: 5%;
    }
     .vf-otc-container{
         margin-left: 27%;
    }
     .vf-timer{
         margin-top: -6% !important;
         margin-left: 42% !important;
    }
}
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {

    .vf-emailphone-width-otp{
		width:100% !important;
    }
    .padding-align-inc-width-pof {
        padding-left: 65px;
        padding-right: 65px;
        padding-bottom: 20px;
      }
    .pof-text-align{
        font-size: 12px !important;
        margin-top: 5px !important;
        text-align: center !important;
    }
    .pof-countrycode-div{
        margin-left: 0%  !important;
        width:100%  !important;
    }
    .pof-padding{
        padding-left: 70px !important;
        padding-right: 70px !important;
    }
    .pof-error{
        text-align: center !important
   }
   .warn-icon-margin{
    margin-right:-10px;
}
     .vf-access-code{
         width: 100%;
         text-align: center;
    }
     .vf-row-size {
         margin-top: 3px;
         min-height: 70px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }
    .vf-row-padding{
        margin-left:40px !important;
        margin-right:40px !important;
    }
     .vf-phone-icon {
         font-size: 27px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
    .vf-kba-icon {
        font-size: 24px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
    }
    .vf-newPhone-icon {
        font-size: 27px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
   }
     .vf-email-icon {
         font-size: 22px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
     .vf-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .vf-chevron-kba-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .vf-text-label {
         color: #086274;
         font-weight: 500;
    }
     .vf-text-label-kba {
         color: #086274;
         font-weight: 500;
    }
     .vf-text {
         color: #4a4a4a;
         margin-top: 3%;
    }
     .vf-text-margin-left {
         margin-top: auto !important;
         margin-bottom: auto !important;
    }
     .vf-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width:100%;
    }
     .vf-sub-form-padding {
         width: 100%;
     //    padding: 5%;
     //    padding-bottom: 0;
    }
     .vf-heading{
         text-align: center;
         font-weight: 500;
         font-size: 14px;
    }
    .vf-heading-2 {
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 6%;
         font-size: 14px;
    }
     .vf-sub-heading{
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 3%;
         margin-top: -3%;
         font-size: 14px;
    }
     .vf-otc-color {
         font-weight: 300 !important;
         color: #4a4a4a;
         font-size: 14px;
    }
     .vf-otc{
         font-weight: 300 !important;
         color: #4a4a4a;
         text-align: center;
         font-size: 16px;
    }
     .vf-otc-error {
         font-size: 16px;
         color: red;
         font-weight: 500 !important;
         text-align: center;
    }
     .vf-cancel-txt{
         font-size:14px;
         margin-left: 5% !important;
    }
     .vf-cancel-btn{
         text-decoration: none !important;
         margin-left: 30%;
         font-weight: 500;
    }
     .vf-otc-txt{
         margin: 0 auto;
         display: block;
         //width: 45%;
    }
     .vf-otc-resend{
         font-size: 14px;
         margin: 0 auto;
         display: block;
         width: 100%;
         text-decoration: none !important;
         font-weight: 500;
    }
     .vf-otc-submit {
         display: block;
         margin: 0 auto;
         margin-top: 5%;
    }
     .vf-otc-container{
         margin-left: 27%;
    }
     .vf-timer{
         margin-top: -6% !important;
         margin-left: 42% !important;
    }
}
 //mobile 
 @media (max-width: 479px) {

    .vf-emailphone-width-otp{
		width:100% !important;
    }
    .padding-align-inc-width-pof {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;
      }
    .pof-countrycode-div{
        margin-left: 0%  !important;
        width:64%  !important;
    }
    .pof-text-align{
        font-size: 12px !important;
        margin-top: 5px !important;
        text-align: center !important;
    }
    .pof-padding{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .pof-error{
        text-align: center !important
   }
   .warn-icon-margin{
       margin-right:-10px;
   }
    .vf-row-padding{
        margin-left:10px !important;
        margin-right:10px !important;
    }

     .vf-font-size {
     font-size: 14px;
}
     .vf-access-code{
         width: 100%;
         text-align: center;
    }
    .fm-row-size {
       
       margin-right: -40px !important;
       margin-left: -40px !important;
   }
     .vf-row-size {
         margin-top: 3px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }
    .vf-margin-size{
        margin-right: -15px !important;
        margin-left: -15px !important;
    }
    .v401k-row-size {
       margin-right: -55px !important;
       margin-left: -55px !important;
   }
     .vf-phone-icon {
         font-size: 24px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
    .vf-newPhone-icon {
        font-size: 16px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
   }
   .vf-kba-icon {
        font-size: 20px !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        color: #086274;
    }
     .vf-email-icon {
         font-size: 16px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
     .vf-chevron-icon {
         font-size: 20px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
     .vf-chevron-kba-icon {
         font-size: 20px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         color: #086274;
    }
     .vf-text-label {
         color: #086274;
         font-weight: 500;
    }
     .vf-text-label-kba {
         color: #086274;
         font-weight: 500;
    }
     .vf-text {
         color: #4a4a4a;
         margin-top: 3%;
    }
     .vf-text-margin-left {
         margin-top: auto !important;
         margin-bottom: auto !important;
    }
     .vf-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width:100%;
    }
     .vf-sub-form-padding {
         width: 100%;
      //   padding: 5%;
      //   padding-bottom: 0;
    }
     .vf-heading{
         text-align: center;
         font-weight: 500;
         font-size: 14px;
    }
    .vf-heading-2 {
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 6%;
         font-size: 14px;
    }
     .vf-sub-heading{
         font-weight: 500;
         margin-left: -3%;
         margin-bottom: 3%;
         margin-top: -3%;
         font-size: 14px;
    }
     .vf-otc-color {
         font-weight: 300 !important;
         color: #4a4a4a;
         font-size: 14px;
    }
     .vf-otc{
         font-weight: 300 !important;
         color: #4a4a4a;
         text-align: center;
         font-size: 16px;
    }
     .vf-otc-error {
         font-size: 16px;
         color: red;
         font-weight: 500 !important;
         text-align: center;
    }
     .vf-cancel-txt{
         font-size:14px;
         margin-left: 5% !important;
    }
     .vf-cancel-btn{
         text-decoration: none !important;
         margin-left: 30%;
         font-weight: 500;
    }
     .vf-otc-txt{
         margin: 0 auto;
         display: block;
        // width: 45%;
    }
     .vf-otc-resend{
         font-size: 14px;
         margin: 0 auto;
         display: block;
         width: 100%;
         text-decoration: none !important;
         font-weight: 500;
    }
     .vf-otc-submit {
         display: block;
         margin: 0 auto;
         margin-top: 5%;
    }
     .vf-otc-container{
         margin-left: 27%;
    }
     .vf-timer{
         margin-top: -6% !important;
         margin-left: 42% !important;
    }
}
