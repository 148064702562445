.reg-form-green-check-icon{
    color: #1a871a;
    padding-left: 10px;
    padding-top: 5px;
}
.reg-form-req-icon{
    color: #940a0a !important;
    font-size: 20px !important;
}
.reg-form-dob-req-icon{
    color: #940a0a !important;
    font-size: 14px !important;
    margin-left: 4px !important;
}  
.reg-form-cmpny-label{
    padding-left:30px !important;
    padding-right:30px !important;
    font-weight: 400 !important;
    color: #000 ; 
    text-align: center !important;
} 
.reg-form-sec-qa-label{
    font-weight: 400 !important;
    color: #000 ;
    text-align: center !important;
    padding-left:30px !important;
    padding-right:30px !important;
}
.reg-form-invalid-text-label{
    color: #940a0a !important;
    margin-right: -100px !important;
}
.reg-form-userid-text-label{
    font-weight: 600 !important;
    margin-right: -20px !important;
    margin-top:5% !important;
}
.reg-form-pwd-label{
    margin-right: -104px !important;
}
.reg-form-conform-pwd-label{
    padding-top: 10px !important;
}
.reg-form-pwd-error-label{
    color: #940a0a !important;
}
.reg-form-pwd-ind-div{
    padding-right: 15% !important; 
    padding-top: 2px !important;
}
.reg-vpilot-terms-div{
    padding-left: 3% !important;
}
.reg-vpilot-terms-label{
    margin-right: -27px !important;
}
.reg-vpilot-terms-span{
    font-size: 13px !important;
}
.reg-vpilot-terms-a{
    font-weight: 400 !important;
}
.reg-req-dropdown-icon{
    color: #940a0a !important;
}
.tnc-modal-header {
    padding: 10px 35px 5px 10px;
    min-height: 49px;
    background-color: #e4e4e4 !important;
    border-bottom: 1px solid #e4e4e4;
    flex-shrink: 0;
    flex-grow: 0;
}

#wfnCheckbox label {
    top: 5px;
}
.white-plain-button {
    color: #000;
    border: 1px solid;
    background-color: #fff;
}

.white-plain-button:hover {
    background-color: #fff !important;
}    

/*.vdl-password-strength__rule-heading {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}*/

.qna-form-align {
    line-height: 7px !important;
}

/*.vdl-textbox > input ::-ms-reveal {
    display: block !important;
}

.tooltip-link{
    color: #086274;
    font-weight: 500;
}*/

.question-circle{
    margin-left: 5px;
    color: #086274;
}
// Small Screens
@media (max-width: 768px) {
  .tnc-print-button-align {
      float: left;
      min-width: 30px !important;
  }
  .tnc-close-button-align {
      min-width: 30px !important;
  }
  .tnc-agree-button-align {
      min-width: 30px !important;
  }
  .tnc-label-align {
      font-size: 14px !important;
  }
  .password-popover {
    position: absolute;
    z-index: 1060;
    max-width: 276px;
    border: 1px solid #bbb;
    background-color: #fff;
    font-family: "Circular";
    width: auto;
    min-width: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
  }
  .password-content {
    max-width: 310px;
    height: 250px;
    overflow-y:scroll;
    }

    .partial-uid-align {
        margin-left: 100px;
        margin-top: -38px;
        float: left;
        width: 50%;
        margin-right: 1em;
        text-align: right;
        font-size: 16px;
   }

   .day-dropdown-cpcflow {
        width: 28% !important;
        margin-left: 18px;
    }

    .year-dropdown-cpcflow {
         margin-left: 10px ;
    }
    .submit-button-align {
        width: auto !important; 
        text-align: center;
    }
    .tooltip-align{
        width: 30%;
    }
   .tooltip-align-identity{
        width: 25%;
    }
    .tooltip-align-userid{
         width: 25%;      
    }
    .full-dob-column-width{
        width: 102px;
    }
}

// Greater than mobile
@media (min-width: 768px) {
.tnc-print-button-align {
      float: left;
  }
.tnc-close-button-align {
      margin-left: 170px !important;
  }
.tnc-agree-button-align {
      float: right;
  }
.tnc-label-align {
      font-size: 14px !important;
      margin-right: -315px;
  }
.password-popover {
    position: absolute;
    z-index: 1060;
    max-width: 332px !important;
    border: 1px solid #bbb;
    background-color: #fff;
    font-family: "Circular";
    width: auto;
    min-width: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
}
.vdl-password-strength__rule {
    list-style: none;
    font-weight: 600;
    margin-bottom: 1px;
    font-size: 13px;
    margin-bottom: 1px;
}
.partial-uid-align {
        margin-left: 100px;
        margin-top: -38px;
        float: left;
        width: 50%;
        margin-right: 1em;
        text-align: right;
        font-size: 16px;
   }

   .day-dropdown-cpcflow {
        width: 28% !important;
        margin-left: 18px;
    }

    .year-dropdown-cpcflow{
        margin-left: 10px ;
    }
    .submit-button-align {
     width: auto !important; 
     text-align: center;
    }
    .tooltip-align{
        width: 30%;
    }
   .tooltip-align-identity{
        width: 25%;
    }
    .tooltip-align-userid{
         width: 25%; 
    }
    .full-dob-column-width{
        width: 102px;
    }
}

@media screen and (max-width: 480px) {
.tnc-print-button-align {
      float: left;
      min-width: 30px !important;
  }
  .reg-form-pwd-label{
    margin-right: 0px !important;
}
 .tnc-close-button-align {
      min-width: 30px !important;
  }
 .tnc-agree-button-align {
      min-width: 30px !important;
  }
  .tnc-label-align {
      font-size: 12px !important;
  }

  .partial-uid-align {
        margin-left: 95px;
        margin-top: -37px;
        float: left;
        width: 50%;
        margin-right: 1em;
        text-align: right;
        font-size: 16px;
   }
   .password-popover {
    position: absolute;
    z-index: 1060;
    max-width: 276px;
    border: 1px solid #bbb;
    background-color: #fff;
    font-family: "Circular";
    width: auto;
    min-width: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
  }
  .password-content {
    max-width: 310px;
    height: 230px;
    overflow-y:scroll;
    }
    .submit-button-align {
     width: auto !important; 
     text-align: center;
    }

    .align-messages {
     left: 27px !important;
    }
    .tooltip-align{
        width: 70%;
        height: 100px;
    }
    .tooltip-align-identity{
        margin-right: 10px;
        margin-top: 9px;
        width: 53% ;
    }
    .tooltip-align-userid{
         width: 53%;  
    }
    .full-dob-column-width{
        width: 75px; 
    }

}
