.fmm-content-div{
    padding-top: 30px !important;
}
.fmm-page-title-h1{
    font-size: 24px !important;
    font-weight: 600 !important;
}
.fmm-page-msgs-div{
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 15px !important; 
}
.fmm-page-msg{
    font-weight: 500 !important;
    text-align: center !important;
    margin-bottom: 2% !important;
}
.fmm-page-msg-transid{
    font-weight: 500 !important;
    text-align: center !important;
    font-size: 12px !important;
}
.fmm-page-footer-content-div{
    padding: 10px !important;
}
.fmm-page-footer-content-label{
    font-size: 12px !important;
}
.fmm-page-footer-imgs-div{
    text-align: center !important;
}