.mobile-footer{
    background-color: black !important;
    color: #BEBEBE !important; 
    left:0 !important;
    bottom: 0 !important;
    padding-left: 15px !important;
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    height:47px !important;
    z-index: 19 !important;
}

.mobile-footer-icon{
    font-size: 20px !important;
    margin-left: auto !important;
    padding-right: 10px !important;
}

.mobile-footer-text{
    width: 90% !important;
    font-size: 12px !important;
    padding-top: 7.5px !important;
    padding-bottom: 7.5px !important;
}

.mobile-chevron-icon{
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto !important;
    color: #086274;
}

.mobile-form-icon{
	width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-top: 25px !important;
    padding-left: 15px !important; 
}

.mobile-form-container{
	width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.vdl-mobile-container{
    margin:0 !important;
    margin-bottom:47px  !important;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
.ssr-mobile-container{
    margin:0 !important;
    margin-bottom:-48px  !important;
    min-height: 100%;
    width: 100%;
  }

.footerheight{
    height: 47px;
}

.vdl-mobile-text{
    margin-top: 1% !important;
}  

.mobile-padding{
    padding-top: 20px !important;
    text-align: center !important;
}

.ssr-mobile-content{
    padding: 10px 15px 0 !important;
}
.vf-mobile-row-size{
    margin-top: 2px;
    background-color: white !important;
    padding:10px 0;
    padding-left: 25px !important;
    padding-right: 15px !important;
    margin-right: -35px !important;
    margin-left: -35px !important;
}

@media (max-width: 479px) {
   body,html{
       height: 100%;
   }
   .content-height{
       height: 100% !important;
   }

}

