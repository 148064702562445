.registration-form-container{
	width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-top: 10px !important;
}

.registration-lang-dropdown{
    margin-left: auto !important;
    margin-top: -6px !important;
    .vdl-dropdown-button__icon {
        font-size: 12px;
        top: -1px;
    }
    .vdl-popup {
        left: -20px;
      }
}

.registartion-cancel-icon-container{
    margin-left: auto !important;
    padding-right: 10px !important;
}

.registration-progress-bar{
    background: #ffffff !important;
    margin-top: 5px !important;
}
.secure-page-div{
    padding-left: 14px !important;
    height: 1.5em !important;
}
.header-hr{
    border-top: 1px solid #BEBEBE !important;
}
.reg-cancel-icon{
    margin-left: auto !important;
    padding-right: 10px !important;
}
@media (max-width: 479px){
  .registration-progress-bar {
    margin-top: 0px !important;
  }
}