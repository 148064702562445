.findme-securepage{
	padding-left: 14px !important;
	height: 1.5em !important;
}
.fm-lp-signup-title{
	font-size: 30px !important;
	font-weight: 600 !important;
}
.fm-web-landingpage{
	text-align: center !important;
	font-weight: normal !important;
}
.fm-emailsearch-title{
	font-size: 24px !important;
	font-weight: 600 !important;
}
.fm-regcode-page-options{
	font-size: 24px !important;
	font-weight: 600 !important;
	margin-Bottom:0px !important;
}
.fm-error-options-page{
	font-weight: 600 !important;
}
.fm-invalid-attempts-options-page{
	text-align: center !important;
	padding-bottom:5px !important;
}
.fm-pii-page{
	font-size: 26px !important;
	font-weight: 600 !important;
}
.fm-centre{
	text-align: center !important;
}
.fm-emailphone-page-content{
	text-align: center !important;
	padding-bottom: 3% !important; 
	line-height: 17px !important;
}
.fm-noemailphone-div{
	text-align: center !important;
	width: 100% !important;
	padding-bottom: 20px !important;
}
.fm-width-100{
	width: 100% !important;
}
.fm-continue-btn-div{
	width: 100% !important;
}
.fm-emailphonesearch-back-btn-div{
	width: 100% !important;
	margin-top: 5px !important;
}
.fm-pii-required-field-icon{
	color: #f00 !important;
	margin-left: 1% !important;
}
.fm-pii-dropdown-container-div{
	display: inline-flex !important;
	width: 100% !important;
}
.fm-pii-month-dropdown-div{
	min-width: 45% !important;
}
.fm-mobile-pii-month-dropdown-div{
	min-width: 42% !important;
}
.fm-identity-margins{
	margin-top: 30px !important;
	margin-bottom: 10px !important;
	width: 100% !important;
}
.fm-pii-day-dropdown-div{
	width: 26% !important;
	margin-left: 2% !important;
}
.fm-mobile-pii-day-dropdown-div{
	width: 33% !important;
	margin-left: 2% !important;
}
.fm-pii-year-dropdown-div{
	width: 28% !important;
	margin-left: 2% !important;
}
.fm-pii-country-dropdown-div{
	width: 100% !important;
}
.fm-pii-ssn-span{
	margin-left: -3% !important;
	color: #086274 !important;
}
.fm-pii-back-span{
	//margin-left: 5px !important;
	font-size: 12px !important;
}
.fm-multi-org-div{
	color: #086274 !important;
	font-weight: normal !important;
}
.fm-text-align-centre{
	text-align: center !important;
}
.fm-error-page-info-span{
	padding-left: 1px !important;
}
.fm-error-page-options-div{
	padding-left: 25px !important;
}
.fm-error-page-option-color{
	color: #086274 !important;
}
.fm-error-page-bottom-padding{
	padding-bottom: 20px !important;
}
.fm-otp-footer-span{
	color: #4a4a4a;
	cursor: default; 
}


.fm-otc-footer-font {
	font-size: 14px;
	text-decoration: none !important;
	font-weight: 500;
}
.fm-otc-footer {
	background: white;
	text-align: center;
	padding: 15px;
	margin-top: -2px;
}
.fm-text-label {
	color: #086274;
	font-weight: 500;
	margin-left: 10%;
}

.fm-country-label {
	color: #086274;
	font-weight: 500;
	margin-left: 9%;
}

.fm-font-size {
	font-size: 14px;
}

.fm-radio-form-padding {
	padding:15px !important;
	padding-top: 0px !important;
	width: 100%;
}

.cdless-hr-align {
	box-sizing: border-box;
	border: 1px solid #979797;
	padding-right: 55%;
}

.cdless-email-radio-info {
	padding-top: 2%;
	padding-left: 0%;
	padding-right: 1%;
}

.cdless-email-radio-content {
	margin: 0 auto;
	padding-left: 10%;
	padding-right:10%;
}

.fm-sivoptions-align {
  margin-left : 11px;
  width : 50% 
}
.fm-radio-align {
  padding-bottom : 15px;
  width: 100%;
}

.fm-variant-alert-align {
  width: 100%;
  margin-bottom: 2% !important;
  margin-top: 2%;
  line-height: 17px !important;	
}
.findme-registrationless-padding {
	padding-bottom: 10px;
	padding-top: 25px;	
	padding-left: 16px;
}
.findme-regless-option {
	color: #6a6a6a;
    font-size: 16px !important;
}
// web 
@media (min-width: 876px) {
	.fm-emailphone-width{
		width:70% !important;
	}

	.fm-mobile-left {
		padding-left: 22px
	}
	.fm-float-left {
		float: left;
	}
	.fm-float-right {
		float: right;
	}
	.fm-radio-width {
		width: 30%;
	}
	.fm-width-5 {
		width: 30%;
		margin-right: 0px;
    margin-left: 15px;
	}
	.fm-width-4 {
		width: 29%;
		margin-right: 0px;
    margin-left: 15px;
	}
	.fm-form-padding {
		padding: 70px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-multi-form-padding {
		padding: 40px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-form-code-padding {
		padding: 30px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	
	.fm-form-info-padding {
		padding: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-form-identity {
		padding: 45px !important;
		padding-top: 0px !important;
		padding-bottom: 10px !important;
		width: 100%;
	}
	.fm-row-size {
		margin-top: 3px;
		min-height: 65px;
		background-color: white;
		padding: 10px;
		 :hover {
			cursor: pointer;
		}
	}
	.fm-image-heigth {
		height: 18px;
		width: 32px;
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.identity-padding-align {
		padding-left: 65px;
		padding-right: 65px;
		padding-bottom: 20px;
	}
	.info-align-padding {
		padding-left: 63px;
		padding-right: 63px;
	}

	.cdless-email-radio-align {
		float: left;
		width:50%;
		padding-left: 25px;
	}

	.cdless-pii-radio-align {
		width: 50%;
		display: inline-block;
		padding-left: 25px;
	}
	
	.padding-findme-siv-align {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 10px;
	}
	.fm-siv-monthdropdown-align {
		margin-left: 24px;
		width: 50%;
	}
	.fm-siv-daydropdown-align {
		margin-left: 10px;
		width: 25%;
	}

	.fm-siv-ssn4-label-align {
		color: #4a4a4a;
	}

	.contractor-footer-div {
		width: 128% !important;
    	margin-left: -14% !important;
		margin-bottom: -17px !important;
	}

	.siv-ssn-fix{
		margin-left: 14px !important;
	}
}

// iPad 
@media screen and (max-width: 875px) and (min-width: 480px) {

	.fm-emailphone-width{
		width:100% !important
	}
	.fm-float-left {
		float: left;
	}
	.fm-float-right {
		float: right;
	}
	.fm-radio-width {
		width: 30%;
	}
	.fm-width-5 {
		width: 30%;
		margin-right: 0px;
		margin-left: 15px;
	}
	.fm-width-4 {
		width: 29%;
		margin-right: 0px;
		margin-left: 15px;
	}
	.fm-form-padding {
		padding: 70px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-multi-form-padding {
		padding: 40px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-form-code-padding {
		padding: 30px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-form-info-padding {
		padding: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-form-identity {
		padding: 45px !important;
		padding-top: 0px !important;
		padding-bottom: 10px !important;
		width: 100%;
	}
	.fm-row-size {
		margin-top: 3px;
		min-height: 65px;
		background-color: white;
		padding: 10px;
		 :hover {
			cursor: pointer;
		}
	}
	.fm-image-heigth {
		height: 18px;
		width: 32px;
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.identity-padding-align {
		padding-left: 65px;
		padding-right: 65px;
		padding-bottom: 20px;
	}
	.info-align-padding {
		padding-left: 63px;
		padding-right: 63px;
	}

	.cdless-email-radio-align {
		float: left;
		width:50%;
		padding-left: 25px;
	}
	
	.padding-findme-siv-align {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 10px;
	}
	.fm-siv-monthdropdown-align {
		margin-left: 24px;
		width: 50%;
	}
	.fm-siv-daydropdown-align {
		margin-left: 10px;
		width: 25%;
	}

	.fm-siv-ssn4-label-align {
		color: #4a4a4a;
	}
	.cdless-pii-radio-align {
	width: 50%;
    display: inline-block;
	padding-left: 25px;
	}
	.contractor-footer-div {
		width: 128% !important;
    	margin-left: -14% !important;
		margin-bottom: -17px !important;
	}
	.siv-ssn-fix{
		margin-left: 14px !important;
	}
}

//mobile 
@media (max-width: 479px) {
	.fm-emailphone-width{
		width:100% !important;
	}
	.fm-form-padding {
		padding: 30px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-multi-form-padding {
		padding: 10px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	
	.fm-form-info-padding {
		padding: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		width: 100%;
	}
	.fm-radio-width {
		width: 100%;
	}
	.fm-width-5 {
		width: 100%;
		margin-right: 0px;
		margin-left: 15px;
	}
	.fm-width-4 {
		width: 100%;
		margin-right: 0px;
		margin-left: 15px;
	}
	.fm-form-identity {
		padding: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 10px !important;
		width: 100%;
	}
	.fm-row-size {
		margin-top: 3px;
		background-color: white;
		padding: 10px;
		 :hover {
			cursor: pointer;
		}
		margin-right: -40px !important;
		margin-left: -40px !important;
	}
	.fm-image-heigth {
		height: 18px;
		width: 20px;
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.identity-padding-align {
		padding-left: 21px;
		padding-right: 24px;
		padding-bottom: 20px;
	}
	.info-align-padding {
		padding-left: 19px;
		padding-right: 16px;
	}

	.cdless-email-radio-align {
		float: left;
		padding-left: 25px;
	}
	.cdless-pii-radio-align {
		display: inline-block;
		padding-left: 25px;
	}
	
	.padding-findme-siv-align {
		padding-bottom: 10px;
	}
	.fm-siv-monthdropdown-align {
		margin-left: 24px;
		min-width: 70%;
	}
	.fm-siv-daydropdown-align {
		margin-left: 10px;
		width: 50%;
	}

	.fm-siv-ssn4-label-align {
		color: #4a4a4a;
		padding-left: 13px;
	}
	.fm-sivoptions-align {
		margin-left : 28px !important;
		width : 50% 
	}
	.contractor-footer-div {
		width: 123% !important;
		margin-left: -30px !important;
	}
	.siv-ssn-fix{
		margin-left: 0px !important;
	}
}