
.t-current-count{
    font-weight: 500;
    font-size: 25px;
    margin-left: 5px;
    max-width: 100%;
    
    
}




.vf-calender{
    margin-top: auto !important;
    margin-bottom: auto !important;
    font-size: 25px;
    
}

.vf-row {
    width: 30%;
}