
.vfadmin-alert {
    width : 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-top: 9px !important;
    margin-bottom: 0px !important;
}

.vfadmin-align {
    margin-top: 20px !important;
}

.vf-admin-align-button {
    margin-bottom: 25px !important;
}
 // web 
 @media (min-width: 876px) {
   
    .vfadmin-countrycode-div{
         margin-left: 16% !important;
         width: 75% !important;
    }

    .vfadmin-phone-label {
        margin-left: 10% !important;
    }

    .vfadmin-ssn-tbox {
        margin-left: 11px !important;
        width: 22% !important;
    }

    .vfadmin-ssn-label {
        margin-left: 22% !important;
    }
}
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {

    .vfadmin-countrycode-div{
        margin-left: 11% !important;
        width: 65% !important;
    }

    .vfadmin-phone-label {
        margin-left: 12% !important;
    }

    .vfadmin-ssn-tbox {
        margin-left: 11px !important;
        width: 22% !important;
    }

    .vfadmin-ssn-label {
        margin-left: 21% !important;
    }
}
 //mobile 
 @media (max-width: 479px) {

    .vfadmin-countrycode-div{
        margin-left: 18% !important;
        width: 40% !important;
    }

    .vfadmin-phone-label {
        margin-left: 18% !important;
    }

    .vfadmin-ssn-tbox {
        margin-left: 11px !important;
        width: 15% !important;
    }

    .vfadmin-ssn-label {
        margin-left: 10% !important;
    }
}
