
.verify-user-lang-drop{
    padding-right: 13px !important;
}

.verify-user-cancel-reg-container{
    margin-left: auto !important;
    padding-right: 10px !important;
}

.verify-user-cancel-reg{
    cursor: pointer !important;
    float: right !important;
    font-size: 14px !important;
    vertical-align: text-bottom !important;
    display: inline-block !important;
    overflow: visible !important;
}

.verify-user-progress-bar{
    background: #ffffff !important;
    margin-top: 5px !important;
}


