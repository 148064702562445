
.vb-registration-content-div{
    padding-bottom: 10px !important;
    background: #F8F8FF !important; 
}
.vb-alertmsg-div{
    padding-bottom: 0px !important;
}
.vb-form-req-icon{
    color: red !important;
    font-size: 20px !important;
}
.vb-form-sbt-btn-div{
    text-align: center !important;
    margin-bottom:30px !important;
    margin-top: 30px !important;
}
.vb-form-draggable-div{
    overflow-y: auto !important;
    width: 300px !important;
    height: 440px !important;
}
.vb-form-draggable-label{
    font-weight: 600 !important;
    text-align: center !important;
}
.vb-form-dob-req-icon{
    color: red !important;
    font-size: 20px !important;
    margin-left: 4px !important;
}
.vb-form-dropdown-div{
    display: inline-flex !important;
    width: 100% !important;
}
.vb-form-dropdown-year-div{
    width: 33% !important;
    margin-left: 2% !important;
}
.vb-freedom-postalcodediv{
    width: 40% !important;
}

.vb-question-circle {
    margin-left: 5px;
    color: #1a8099;
    cursor: pointer;
}
 
.vb-touch-scroll {
    -webkit-overflow-scrolling: touch !important;
    }

 .vb-submit {
         display: block;
         margin: 0 auto;
    }

.vb-cancel-btn {
         text-decoration: none !important;
         margin: 0 auto;
         display: block !important;
    }

    .vb-sample-doc {
         color: #1a8099;
          font-size: 16px; 
          font-weight: 500; 
         cursor: pointer;
    }
    .vb-draggable-header {
        position: fixed;
         z-index: 200;
    }
 .vb-draggable-body {
 background: white;
  width: 320px;
   cursor: move;
    padding: 10px 
 }
 .vb-draggable-close {
 color: #676767;
  font-size: 30px;
   cursor: pointer;
 }
 // web 
 @media (min-width: 876px) {

    .padding-align-inc-width-bev {
        padding-left: 65px;
        padding-right: 65px;
        padding-bottom: 20px;
      }
    
 .vb-tooltip {
        width: 15%;
    }

 }
  .vb-tooltip-align-identity{
        width: 25%;
    }
 


 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {

    .padding-align-inc-width-bev {
        padding-left: 65px;
        padding-right: 65px;
        padding-bottom: 20px;
      }
     
 .vb-tooltip {
        width: 15%;
    }

   .vb-tooltip-align-identity{
        width: 25%;
    }
 
 }


 //mobile 
 @media (max-width: 479px) {
    .padding-align-inc-width-bev {
        padding-left: 10px;
        padding-right: 10px;
      }
     
   .vb-tooltip {
        margin-right: 10px;
        margin-top: 9px;
        width: 53% ;
    }
   .vb-tooltip-align-identity{
        width: 50%;
    }
 
 }



   