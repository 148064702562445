
.ev-list-div{
    margin-left: 2% !important;
}
.ev-list-label-div{
    display: inline !important; 
}
.ev-list-span{
    color: #4a4a4a ; 
}
.ev-list-img-div{
    display: inline-block !important; 
}

.ev-list-img{
    bottom: -3px !important;
    position: relative !important;
    display: block !important;
}
.ev-list-subtitle-div{
    display: inline !important;
    margin-left: 2% !important; 
    color: #4a4a4a !important;
}

.ev-margin-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.ev-margin-h {
    margin-left: auto;
    margin-right: auto;
}
.ev-border {
    border: 1px solid lightgrey;
}

.ev-col-2 {
    width: 18%;
    max-width: 18%;
}

.ev-col-9 {
    width: 75%;
}

.ev-col-10 {
    width: 83%;
}

.ev-col-11 {
    width: 93%;
}

.ev-col-1 {
    width: 5%;
}
.ev-col-sm-2 {
  max-width: 20%;
}
.ev-col-sm-8 {
 max-width: 60%;
 margin-left: 5%;
} 

.ev-font-size {
     font-size: 14px;
}
.ev-sub-title {
   color: #4a4a4a;
    float: left;
    margin-left: 2%;
    width:100%;
}
.ev-sub-image-heigth{
    height: 15px;
    width: 25px;
}


.ev-sub-text-label{
    color: #086274;
    font-weight: 500;
   
}
 // web 
 @media (min-width: 876px) {
    
    .ev-image-heigth {
 height: 90%;
 width: 85px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

    .ev-sub-title {
    font-size: 14px;
    }


     .ev-text-margin-left {
         margin-left: -5%;
         margin-top: auto !important;
         margin-bottom: auto !important;
    }
     .ev-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width: 100%;
    }
     .ev-row-info-size {
         height: 50px;
         background-color: #DCDCDC;
         padding: 10px;
    }
     .ev-row-size {
         margin-top: 3px;
         min-height: 60px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }
     .ev-th-icon {
         font-size: 23px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         margin-left: -1px !important;
         color: #086274;
    }
     .ev-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .ev-manual-text {
         margin-top: auto;
         margin-bottom: auto;
         font-weight: 500;
         text-align: center;
    }
     .ev-text-label {
         color: #086274;
         font-weight: 500;
         margin-left: 2%;
    }
     .ev-heading {
         text-align: center;
         font-weight: 500;
         margin-bottom: 6%;
         font-size: 15px;
        ;
    }
}
 // iPad 
 @media screen and (max-width: 875px) and (min-width: 480px) {

.ev-image-heigth {
 height: 90%;
 width: 85px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
      .ev-sub-title {
    font-size: 14px;
    }

     .ev-text-margin-left {
         margin-left: -5%;
         margin-top: auto !important;
         margin-bottom: auto !important;
    }
     .ev-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width: 100%;
    }
     .ev-row-info-size {
         height: 50px;
         background-color: #DCDCDC;
         padding: 10px;
    }
     .ev-row-size {
         margin-top: 3px;
         min-height: 60px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
    }
     .ev-th-icon {
         font-size: 23px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         margin-left: -1px !important;
         color: #086274;
    }
     .ev-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .ev-manual-text {
         margin-top: auto;
         margin-bottom: auto;
         font-weight: 500;
         text-align: center;
    }
     .ev-text-label {
         color: #086274;
         font-weight: 500;
         margin-left: 2%;
    }
     .ev-heading {
         text-align: center;
         font-weight: 500;
         margin-bottom: 6%;
         font-size: 15px;
        ;
    }
}
 //mobile 
 @media (max-width: 479px) {

     .ev-image-heigth {
 height: 90%;
 width: 20%;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
 .ev-sub-title {
    font-size: 10px;
    }


     .ev-text-margin-left {
         margin-left: -5%;
         margin-top: auto !important;
         margin-bottom: auto !important;
         max-width: 70% !important;
    }
     .ev-form-padding {
         padding:30px !important;
         padding-top: 0px !important;
         width: 100%;
    }
     .ev-row-info-size {
         height: 50px;
         background-color: #DCDCDC;
         padding: 10px;
    }
     .ev-row-size {
         margin-top: 3px;
         background-color: white;
         padding: 10px;
         :hover{
             cursor: pointer;
        }
        margin-left:-50px !important;
        margin-right: -50px !important;
    }
     .ev-th-icon {
         font-size: 23px !important;
         margin-top: auto !important;
         margin-bottom: auto !important;
         margin-left: -1px !important;
         color: #086274;
    }
     .ev-chevron-icon {
         font-size: 20px !important;
         margin-top: auto;
         margin-bottom: auto;
         color: #086274;
    }
     .ev-manual-text {
         margin-top: auto;
         margin-bottom: auto;
         font-weight: 500;
         text-align: center;
    }
     .ev-text-label {
         color: #086274;
         font-weight: 500;
         margin-left: 2%;
    }
     .ev-heading {
         text-align: center;
         font-weight: 500;
         margin-bottom: 6%;
         font-size: 15px;
        ;
    }
}
